@import "~react-tagging-input/dist/styles.css";
.react-tags__container > li {
  background: gold;
  padding-right: 0;
}
.react-tags input,
.react-tags__container > li {
  font-size: 16px;
  border-radius: 4px;
}
.react-tags__container a {
  font-size: 16px;
  padding: 0 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #555;
  color: #fff;
}
.react-tags input {
  border: none;
  border-bottom: 1px solid #999;
  flex: 1;
  width: 100%;
  border-radius: 0;
  padding: 0;
  padding-bottom: 8px;
  margin-top: 16px;
}
